import React from 'react';
import Layout from '../components/layout';
import { Clients } from '../components/Clients';
import { graphql } from 'gatsby';

const Showcase = ({ data }) => {
	// console.log({ data });
	return (
		<Layout>
			<div className="justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
				<div className="pb-5">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide text-black ml-2">WHO WE WORK WITH</span>
				</div>
				<h1 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl pb-5 text-black">Clients & Partners</h1>
				<h2 className="text-2xl font-thin text-black-us-light leading-normal">
					We feel privileged to work with some extraordinary organizations.
				</h2>

				<Clients data={data} />
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
		rem2: file(relativePath: { eq: "REM2.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		know: file(relativePath: { eq: "KNOW.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		rem3: file(relativePath: { eq: "REM3.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		pastelu: file(relativePath: { eq: "PAST.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		alpha1: file(relativePath: { eq: "ALPHAC1.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;

export default Showcase;
