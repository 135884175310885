import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const Client = styled.div`
	border-radius: 5px;
	padding: 20px;
	font-size: 150%;
	vertical-align: baseline;
`;

class Clients extends Component {
	render() {
		let { data } = this.props;

		return (
			<Container>
				{Object.keys(data).map((key, index) => (
					<Client key={key}>
						<Img fluid={data[key].childImageSharp.fluid} />
					</Client>
				))}
			</Container>
		);
	}
}
export { Clients };
